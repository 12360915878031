<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Dashboard
      </v-card-title>

      <v-card>
        <entry-list/>
      </v-card>
      <v-btn
        v-if="!showEditEntry"
        @click="newEntry()"
        color="info"
      >New Entry</v-btn>
      <div v-if="showEditEntry">
        <v-card >
          <entry-edit />
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mdiHome, mdiLoupe } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import { TAGS_URL, ENTRIES_URL } from '@/constants/urls'

import EntryEdit from '@/components/EntryEdit'
import EntryList from '@/components/EntryList'

export default {
  components: {
    'entry-edit': EntryEdit,
    'entry-list': EntryList
  },
  data: () => ({
    active: null,
    'mdi-home': mdiHome,
    'mdi-loupe': mdiLoupe,
    // move this to vuex (enables cancel)
    showEditEntry: false,
    idx: 0,
    offsetY: 100,
    mlist: [
      { title: 'Home', icon: 'mdi-home', link: '/dashboard' },
      { title: 'Entries', icon: 'mdi-loupe', link: '/entries' },
      { title: 'Tags', icon: 'mdi-loupe', link: '/tags' }
    ]
  }),
  mounted () {
    this.loadQuery(
      {
        id: 'entries',
        url: ENTRIES_URL,
        params: {},
        mutation: 'entry/setEntries'
      }
    )
    this.loadQuery(
      {
        id: 'tags',
        url: TAGS_URL,
        params: {},
        mutation: 'entry/setTags'
      }
    )
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
      // getNavMenu: 'nav/getNavMenu',
      // getTabActive: 'nav/getTabActive'
    }),
    drawer () {
      if (this.$vuetify.breakpoint.name === 'sm') {
        return false
      }
      return true
    }
  },

  methods: {
    ...mapActions({
      loadQuery: 'qry/loadQuery',
      clearEntryEdit: 'entry/clearEntryEdit',
      clearSectionEdit: 'entry/clearSectionEdit',
      setEntryEdit: 'entry/setEntryEdit',
      setSectionEdit: 'entry/setSectionEdit',
      saveSectionEdit: 'entry/saveSectionEdit',
      setEditFlag: 'entry/setEditFlag',
      clearEditId: 'entry/clearEditId'
      // loadBreadcrumbs: 'nav/loadBreadcrumbs',
      // loadCurNav: 'nav/loadCurNav',
      // setNavMenu: 'nav/setNavMenu',
      // loadVue: 'res/loadVue'
    }),
    newEntry () {
      this.clearEditId()
      this.clearEntryEdit()

      this.setEntryEdit(
        {
          id: null,
          title: '',
          shortDesc: '',
          visible: false,
          article: {},
          tags: []
        }
      )
      this.clearSectionEdit()
      // NOTE: is this used?
      this.setEditFlag({ editId: 'entry', value: true })
      this.showEditEntry = true
    }
  }
}
</script>
