<template>
  <v-container>
    <v-card
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th/>
              <th/>
              <th/>
              <th class="text-left">
                Entries
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, i) in entriesList"
              :key="i"
            >
              <td width="50px">
                <v-icon
                  color="green"
                  @click="toEditEntry(entry)"
                >
                  mdi-pencil
                </v-icon>
              </td>
              <td width="50px">
                <v-icon
                  color="red"
                  @click="deleteEntry({ id: entry.id })"
                >
                  mdi-delete-forever
                </v-icon>
              </td>
              <td> {{ entry.id }} </td>
              <td
                @click="entryLink(entry)"
              >
                {{ entry.title }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { mdiPencil, mdiDeleteForever } from '@mdi/js'

export default {
  name: 'EntryList',

  props: ['sectionLimit'],
  data: () => ({
    currentTag: null,
    selectedEntry: null,
    'mdi-pencil': mdiPencil,
    'mdi-delete-forever': mdiDeleteForever
  }),
  computed: {
    ...mapGetters({
      getEntries: 'entry/getEntries'
    }),
    entriesList () {
      const tmp = []
      if (this.getEntries({}) !== null && this.getEntries({}) !== undefined) {
        const entries = JSON.parse(JSON.stringify(this.getEntries({})))
        for (const key in entries) {
          tmp.push(entries[key])
        }
      }
      return tmp
    }
  },
  methods: {
    ...mapActions({
      deleteEntry: 'qry/deleteEntry'
    }),
    tagStr (tag) {
      return tag.replace('_', ' ')
    },
    entryLink (entry) {
      this.$router.push('/entries/' + entry.id + '/' + entry.slug)
    },
    toEditEntry (entry) {
      this.$router.push('/entry-edit/' + entry.id)
    }
  }
}
</script>
